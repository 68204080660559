<template>
  <b-button
    :variant="isConnect? 'primary' : 'outline-primary'"
    size="sm"
    :class="isConnect? 'h-[70%]' :'!border-1 !border-black !text-black'"
    @click="onSubmit"
  >
    <div class="flex items-center gap-2">

      <img
        width="20"
        :class="isConnect? 'invert-image' : '' "
        :src="!isConnect ? 'https://storage.googleapis.com/komerce/assets/LP-Komchat/google-icon.svg' : 'https://storage.googleapis.com/komerce/komads-meta/logout.svg'"
        alt="icon google"
      >
      <div :class="isConnect? 'text-white' : 'text-black'">
        {{ isConnect ? 'Putuskan' : 'Sign in with Google' }}
      </div>
    </div>
  </b-button>
</template>

<script>
import { apiSignInGoogle, apiSignOutGoogle, apiSignInGoogleVerify } from '@/views/pages/komship/customer/Broadcast/service/api.groupContact.service'

export default {
  props: {
    isConnect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      authWindow: {},
      dialogUrl: '',
      params: new URLSearchParams(window.location.search),
      authCode: '',
    }
  },
  methods: {
    onSubmit() {
      if (!this.isConnect) {
        this.importFromGoogle()
      } else {
        this.$emit('onDisconnectGoogle')
      }
    },
    async importFromGoogle() {
      try {
        const { data } = await apiSignInGoogle()
        this.dialogUrl = data.data.url
        this.openGoogleSignIn()
      } catch (error) {
        this.$toast_error('Gagal saat login google')
      }
    },
    openGoogleSignIn() {
      this.authWindow = window.open(this.dialogUrl, 'Google Sign-In', 'width=600,height=700')
      this.pollOAuthData()
    },
    pollOAuthData() {
      const pollingInterval = setInterval(() => {
        try {
          const oauthData = localStorage.getItem('google_oauth_code')
          if (oauthData) {
            const { code } = JSON.parse(oauthData)
            this.authCode = code
            this.verifySignIn()
            localStorage.removeItem('google_oauth_code')
            clearInterval(pollingInterval)
          }
          if (this.authWindow.closed) {
            clearInterval(pollingInterval)
          }
        } catch (error) {
          this.$toast_error('Gagal saat login google')
        }
      }, 1000)
    },
    async verifySignIn() {
      try {
        await apiSignInGoogleVerify(this.authCode)
        this.$emit('getStatusConnect')
      } catch {
        this.$toast_error('Gagal verifikasi login google')
      }
    },
  },
}
</script>

<style scoped>

.invert-image {
  filter: invert(100%) brightness(1200%);
}
</style>
