<template>
  <BButton
    variant="outline-primary "
    class="button-facebook"
    :class="statusConnection ? 'button-logout-facebook': ' !text-black !border !border-black button-login-facebook'"
    size="sm"
    data-auto-logout-link="true"
    @click="onSubmit"
  >
    <BImg
      width="23"
      :src="
        statusConnection
          ? 'https://storage.googleapis.com/komerce/komads-meta/logout.svg'
          : 'https://storage.googleapis.com/komerce/komads-meta/logo-facebook-primary.svg'
      "
    />
    <div>{{ statusConnection ? 'Putuskan' : 'Sign in with Facebook' }}</div>
  </BButton>
</template>

<script>
import { defineComponent, ref, onMounted } from '@vue/composition-api'

export default defineComponent({
  props: {
    cookie: {
      type: Boolean,
      default: false,
    },
    xfbml: {
      type: Boolean,
      default: false,
    },
    version: {
      type: String,
      default: 'v20.0',
    },
    lang: {
      type: String,
      default: 'en_US',
    },
    scope: {
      type: String,
      default: '',
    },
    fields: {
      type: String,
      default: '',
    },
    loadingProps: {
      type: Boolean,
      required: true,
    },
    statusConnection: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const sdkLoaded = ref(false)
    const isLoading = ref(false)
    const FACEBOOK_APP_ID = process.env.VUE_APP_FACEBOOK_APP_ID
    const FACEBOOK_CONFIG_ID = process.env.VUE_APP_FACEBOOK_CONFIG_ID
    const ME = '/me'

    const loadFacebookSDK = () => {
      if (window.FB) {
        sdkLoaded.value = true
        return
      }

      window.fbAsyncInit = () => {
        window.FB.init({
          appId: FACEBOOK_APP_ID,
          cookie: props.cookie,
          xfbml: props.xfbml,
          version: props.version,
        })

        window.FB.AppEvents.logPageView()

        if (props.statusConnection) {
          window.FB.getLoginStatus(response => {
          // eslint-disable-next-line no-use-before-define
            statusChangeCallback(response)
          })

          window.FB.Event.subscribe('auth.statusChange', response => {
          // eslint-disable-next-line no-use-before-define
            statusChangeCallback(response)
          })
        }

        sdkLoaded.value = true // Update the SDK loaded state
      }

      // eslint-disable-next-line func-names
      (function (d, s, id) {
        const js = d.createElement(s)
        const fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id) || !fjs || !fjs.parentNode) {
          return
        }
        js.id = id
        js.src = `https://connect.facebook.net/${props.lang}/sdk.js`
        fjs.parentNode.insertBefore(js, fjs)
      }(document, 'script', 'facebook-jssdk'))
    }

    const statusChangeCallback = response => {
      const { status } = response

      if (status === 'connected') {
        window.FB.api(
          ME,
          { fields: props.fields },
          me => {
            Object.assign(response, me)
            props.callback(response)
            isLoading.value = false
          },
        )
      }
    }

    const handleLogin = () => {
      if (!sdkLoaded.value) {
        console.error('Facebook SDK not loaded yet')
        return
      }

      isLoading.value = true

      window.FB.login(
        response => {
          statusChangeCallback(response)
        },
        {
          scope: props.scope,
          return_scopes: true,
          config_id: FACEBOOK_CONFIG_ID,
        },
      )
    }

    const handleLogout = () => {
      if (!sdkLoaded.value) {
        console.error('Facebook SDK not loaded yet')
        return
      }

      props.callback()
    }

    const onSubmit = () => {
      if (props.statusConnection) {
        handleLogout()
      } else {
        handleLogin()
      }
    }

    onMounted(() => {
      loadFacebookSDK()
    })

    return {
      sdkLoaded,
      isLoading,
      onSubmit,
    }
  },
})
</script>

<style scoped>
.button-facebook {
  display: flex !important;
  align-items: center;
  gap: 0.5rem;
  border-radius: 6px;
  padding: 4px 8px;
  cursor: pointer;
}

/* .button-login-facebook {
  border: 1px solid #0865FE !important;

  color: white;
} */

.button-logout-facebook {
  border: 1px solid #e31a1a !important;
  background: none !important;
  color: #e31a1a !important;
}
</style>
